body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  -webkit-user-select: none;
  /*webkit浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}








/**** 第三方插件米字背景***/
.ci-box {
  width: 150px;
  height: 150px;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #980f29;
  position: relative;
  overflow: hidden;
}

.ci-box .lt-2rb {
  width: 147.07821049%;
  height: 147.07821049%;
  display: inline-block;
  border: 1px dashed #980f29;
  transform: rotate(45deg) translate(-25%, 50%);
  position: absolute;
  left: 0;
  top: 0;
}

.ci-box .rt-2lb {
  width: 147.07821049%;
  height: 147.07821049%;
  display: inline-block;
  border: 1px dashed #980f29;
  transform: rotate(-45deg) translate(25%, -50%);
  position: absolute;
  right: 0;
  top: 0;
}

.ci-box .rm-2lm {
  width: 106%;
  height: 100%;
  display: inline-block;
  transform: translate(-3%, 50%);
  position: absolute;
  border: 1px dashed #980f29;
  top: 0;
  left: 0;
}

.ci-box .tm-2bm {
  width: 100%;
  height: 106%;
  display: inline-block;
  transform: translate(-50%, -3%);
  position: absolute;
  border: 1px dashed #980f29;
  left: 0;
  top: 0;
}

.ci-box .ci {
  position: relative;
  font-family: "楷体";
  font-weight: bold;
  height: 100%;
  font-size: 0;
  text-align: center;
  white-space: nowrap;
}

.ci-box .ci .wd {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 40px;
}

.ci-box .ci:before {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}

/*# sourceMappingURL=main.css.map */

/*设置自定义css样式,  整体宽高, 字体大小*/
/* .ci-box{
  width:96px!important;
  height:96px!important;
  margin: 0 2px;
}

.ci-box .wd {
  font-size:90px!important;
} */

a {
  color: #555;
  text-decoration: none;
}

a:hover {
  color: #000;
  text-decoration: none;
}

.button:hover,
.text--no-decoration,
.text--no-decoration:hover,
a:not([href]) {
  text-decoration: none
}

p {
  margin: 0 0 var(--ifm-paragraph-margin-bottom)
}
.task-list{
  padding:10px;
}
.task-list ol,
.task-list li,
.task-list a {
  list-style: none;
  margin: 0;
  padding: 0;
  color: rgb(102,102,102);
  text-decoration: none;
}
.task-list li{
  margin:10px 5px;
}
.task-list .item {
  border: 1px solid #333;
  list-style: none; 
  background-color: #fff;
  padding: 5px 0px 5px 20px;
  border-radius: 20px; 
  color:#aaa;
}
.task-list h5{
  margin-top:20px;
  color:#999;
}

.task-list .dot, .task-list .dot1, .task-list .dot2{
  display: inline-block;
  width:20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid #aaa;
  vertical-align: text-bottom;
  margin-right: 10px;
}
.task-list .dot1{
  background-color: #eee;
}
.task-list .dot2{
  background-color: #aaa;
}
.task-list a.t1{
  color: #000;
}
.task-list .done .item{
  background-color:#f9f9f0;
}
.p-list{
  padding:0.5rem;
}
.p-list ol,
.p-list li,
.p-list a {
  list-style: none;
  margin: 0;
  padding: 0;
  color: rgb(102,102,102);
  text-decoration: none;

}

.p-list .item{
  width:8rem;
  height:4rem;
  display: inline-block;
  border: 1px solid #333;
  border-radius: 0.5rem;
  margin:0.5rem;
  text-align: center;
  vertical-align: middle;
  color: #000;
  background-color: #5cd669;
}
.p-list .item .sc{
  background-color: #000;
  width: 5rem;
  border-radius: 0.5rem;
  margin:auto;
  color:white;
  font-size: .8rem;
}


.header-user{
  padding:25px 25px 15px 25px;
}
.header-user .u-name{ 
  font-size: 1.5em;
}
.header-user .u-money{
  font-size: 1.4em; 
  color:#980f29;

}
.header-user svg{
  vertical-align: baseline;
  margin-right:5px;
}
.footbar .a{
  border:1px solid #333;
  border-radius: 30px;
  padding:0px 10px;
}

.footbar .b{
  background-color: cornflowerblue;
  border-radius: 1.3rem;
  width: 2.6rem;
  height: 2.6rem;
  text-align: center;
  font-size: 1.1rem;
  color:white !important;
  margin-left:1rem;
}

/**** 第三方插件***/
.han {
  width: 100%;
  height: 410px;
}



.draw-box {
  width: 100%;
  height: 220px;
}


.pad-title {
  padding: 5px 0px 0px 5px;
}

.pad-text {
  padding: 5px;
}

.submit-tool {
  padding-top: 5px;
  padding-right: 5px;
}


.disable-select {
  -webkit-user-select: none;
  /* Safari/Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.write_pad {
  height: 100vh;
  /* display: flex;
  flex-direction: row; */
  justify-content: space-between;
  align-items: stretch;
}

.head-tool {
  flex: 50px 0 0;
  background-color: rgb(215, 250, 234);
}

.head-tool-w {
  flex: 50px 0 0;
  background-color: rgb(215, 250, 234);
}

.head-tool-w .progress {
  border-radius: 0px;
}

.head-tool-w .t-up {
  padding: 5px 5px;
}

.head-tool-w .col2 {
  line-height: 40px;
}

.t-box {
  position: absolute;
  width: 100%;

}

.t-box .t-menu {
  position: relative;
  top: 0px;
  width: 60%;
  float: left;
  line-height: 50px;
}

.t-box .t-submit {
  position: relative;
  right: 10px;
  top: 5px;
  text-align: right;
  width: 40%;
  float: right;
}

.pad-tool,
.keypad-tool {
  flex: 1;
}

.write_pad ._left {
  flex: 0 0 38%;
}

.write_pad ._right {
  flex: 1;
  /* 随父级变化 */
}

.pad-w {
  flex: 1;
}

.pad-w-down {
  flex: 1;
}


.t-w-box5 {
  width: 100%;
  bottom: 0px;
  position: fixed;
}

.write_pad .t-w-box {
  width: 100%;
  height: 600px;
  bottom: 0px;
  position: fixed;
}

.t-w-box #canvas,
.t-w-box .ci-box {
  left: 50%;
  /** top:50%;    **/
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 80px;

}

.t-w-box .show-result,
.t-w-box .show-result2 {
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  font-weight: bold;
}

.t-w-box .show-result span,
.t-w-box .show-result2 span {
  display: inline-block;
  padding: 0px 3px;

}

.t-w-box .ci-box {
  width: 320px !important;
  height: 320px !important;
  border-radius: 5px;
  opacity: 0.2;
}

.canvas-tool {
  position: absolute;
  bottom: 15px;
  width: 320px;

  left: 50%;
  /** top:50%;    **/
  transform: translate(-50%, 0);
}

.canvas-tool .btn {
  width: 48%;
  border: 1px solid #eee;
  border-radius: 5px;
  height: 48px;
}

.canvas-tool .btn:hover {
  background-color: bisque;
}



.t-w-bg0,
.t-w-bg1,
.t-w-bg2,
.t-w-bg3 {
  position: absolute;
  top: 10px;

  left: 0;
  right: 0;
  opacity: 0.2;
  margin: auto;
}

.t-w-bg1 {
  left: 120px;
}

.t-w-bg2 {
  left: 230px;
}

.t-w-bg3 {
  left: 340px;
}

/** 条纹 **/
.tl-background {
  /* background-image: linear-gradient(0deg,white 99.2%,#e7e7e7 0); */
  background-size: 100% 80px;
}

.bd-s .tl-background {
  background-image: none;
  background-color: transparent;
}

.r-score {
  color: red;
  padding-right: 10px;
}

button.start-btn,a.start-btn{
  border-radius: 20px;
  border: 1px solid gray;
}

button.btn-submit,a.btn-submit{
  border-radius: 18px;
}


.input {
  width: 100%;
  border: 0;
  font-size: 2em;
}

textarea {
  white-space: pre-wrap;
  height: 50%;
  background: transparent;
  border-style: none;

}

textarea:focus {
  border: 0;
  outline: none;
}

.subject_list a {
  text-decoration: none;
}

.subject_list .name {
  padding-right: 10px;
}

.keypad-t {
  background-color: antiquewhite;
  flex: 50px 0 0;
}

.keypad-body {
  flex: 1;
}

.keypad-body .simple-keyboard-preview {
  flex: 82% 0 0;
  /* background-color: #f0f0f0; */
  position: relative;
}

.simple-keyboard-preview textarea {
  width: 100%;

}

.text_bg {
  /* background-color: #f0f0f0; */
  width: 100%;
  height: 100%;
}

.keypad-body .preview2 {
  flex: 1;
  position: relative;
}

.keypad-body .simple-keyboard {

  position: absolute;
  bottom: 0;

}

.hg-theme-default .hg-button {
  height: 60px !important;

}

.simple-keyboard.hg-theme-ios.hg-theme-default .hg-button {

  font-weight: 400;
  font-size: 20px;
  font-weight: bold;
  height: 60px;
  min-height: 60px;
}

.r-score2 {
  color: red;
  line-height: 40px;
}

.input-s {
  padding: 10px;
}

.h200 {
  height: 200px;
}

.pad-card {
  padding: 10px;
  height: 100%;
  position: relative;
}

.pad-card .pad-prompt {
  position: absolute;
  bottom: 0;
}


.sv {
  background-color: rgb(249, 250, 252);
}


.sent-t {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.sent-b {

  background-color: bisque;
  padding: 10px;
  line-height: 35px;
}

.sent-b h3 {
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
}

.result {
  padding: 10px;
}

.btn-c {
  border-radius: 20px !important;
  height: 32px;
  width: 60px;
  line-height: 24px !important;
  font-size: 14px !important;
  padding: 2px !important;
}

#inputResult {
  font-size: 24px;
  font-weight: bold;
}

.default-none {
  display: none;
}

.show-true-1,
.show-true2 {
  display: block;
}
 
.end-true{
  display: none;
}



.poe-char{
  display: inline-block;
  padding:2px;
  font-size: 1.5em;
}


/** home **/
.bi-emoji-smile,
.bi-emoji-smile-fill {
  color: rgb(13, 202, 240);
}

.bi-emoji-smile-fill {
  color: chocolate;
}

.page-header {
  padding: 10px 0px;
}

.page-header-tool {
  clear: both;
  height: 50px;
}

.task_num {
  font-size: 24px;
  color: blueviolet;
  display: inline;
  padding: 0px 5px;
}

.smile {
  display: none;
  padding-right: 15px;
}

.bi-act {
  display: inline;
}